/* eslint-disable no-useless-escape */
module.exports = {
  /**
   * Check and verify if string is an email and validate
   * @param {string} string String to be checked and validated
   * @returns True or False if email valid or invalid
   */
  validateEmail(string) {
    const re =
      /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/ // eslint-disable-next-line no-useless-escape
    return re.test(string)
  },
}
