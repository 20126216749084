<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
        <b-col>
          <h6 class="brand-text text-primary ml-1 text-uppercase mt-1">
            County Government of
          </h6>
          <h4 class="brand-text text-primary ml-1 text-uppercase">Kericho</h4>
        </b-col>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Kericho County" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-sm-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto mt-4">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Kericho CPSB! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please login to your account to access this portal
          </b-card-text>

          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group
                label="Username or Email"
                label-for="login-identifier"
              >
                <validation-provider
                  #default="{ errors }"
                  name="loginID"
                  rules="required"
                >
                  <b-form-input
                    id="login-identifier"
                    v-model="userID"
                    :state="errors.length > 0 ? false : null"
                    name="login-identifier"
                    placeholder="john.doe or john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link
                    :to="{
                      name: 'coming-soon',
                    }"
                    :tabindex="1"
                  >
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="remember_me"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="!isValid || loading"
                @click="validationForm"
              >
                <b-spinner v-if="loading" small type="grow" />
                <span v-if="!loading">Login</span>
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 mb-2">
            <span>New on our portal? </span>
            <b-link :to="{ name: 'register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <visitors-badge :minimal="false" />

          <div class="mt-2 text-center">
            <version />
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import keys from '@/utils/keys'
import jwt from '@/utils/jwt'
import emailUtil from '@/utils/email'

import { defineAbilities } from '@/libs/acl/config'
import VisitorsBadge from '@/components/stats/VisitorsBadge.vue'
import Version from '@/components/misc/Version.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BSpinner,
    Logo,
    ValidationProvider,
    ValidationObserver,
    VisitorsBadge,
    Version,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      remember_me: '',
      password: '',
      userID: '',
      loading: false,
      sideImg: require('@/assets/images/flags/kcg-logo.png'),
      // validation rulesimport store from '@/store/index'
      required,
      min,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    isValid() {
      return this.userID !== '' && this.password !== ''
    },
  },
  methods: {
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
        },
        {
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(async success => {
        if (success) {
          this.loading = true
          const payload = {
            remember_me: this.remember_me,
            username: this.userID.trim(),
            password: this.password.trim(),
          }
          if (emailUtil.validateEmail(this.userID.trim())) {
            delete payload.username
            payload.email = this.userID
          }
          await this.$store
            .dispatch('auth/login', payload)
            .then(async res => {
              const response = res.data
              if (res && response && 'data' in response) {
                this.showToast(
                  'Processing',
                  'Please wait while we verify the login response',
                  'Shield',
                  'warning',
                  // eslint-disable-next-line comma-dangle
                  5000,
                )
                localStorage.setItem('token', response.data.token)
                const jwtPayload = await jwt.verify(
                  keys.public_key(),
                  // eslint-disable-next-line comma-dangle
                  response.data.token,
                )
                if (jwtPayload) {
                  this.$store.commit(
                    'auth/SET_BEARER',
                    // eslint-disable-next-line comma-dangle
                    jwtPayload.access_token,
                  )
                  this.$store.commit(
                    'auth/UPDATE_JWT_TOKEN',
                    // eslint-disable-next-line comma-dangle
                    response.data.token,
                  )
                  this.$store.commit(
                    'auth/UPDATE_AUTH_USER',
                    // eslint-disable-next-line comma-dangle
                    jwtPayload.user,
                  )
                  this.$store.commit(
                    'auth/UPDATE_ACCESS_TOKEN',
                    // eslint-disable-next-line comma-dangle
                    {
                      access_token: jwtPayload.access_token,
                      refresh_token: jwtPayload.refresh_token,
                      // eslint-disable-next-line comma-dangle
                    },
                  )
                  this.showToast(
                    response.message,
                    `Welcome back, ${response.data.user.given_name} ${response.data.user.family_name}`,
                    'Check',
                    'success',
                    // eslint-disable-next-line comma-dangle
                    10000,
                  )
                  this.$socket.client.emit('SUBSCRIBE', jwtPayload.sub)
                  this.$socket.client.emit('SUBSCRIBE', jwtPayload.user.role)
                  const ability = defineAbilities(jwtPayload.user)
                  this.$ability.update(ability)
                  this.$store.dispatch('auth/getUserPersonalInfo')
                  this.$store.dispatch('auth/getUserInformation')
                  this.$store.dispatch('auth/getUserEducation')
                  this.$store.dispatch('auth/getUserExperience')
                  this.$store.dispatch('auth/getUserReferees')
                  this.$store.dispatch('auth/getUserDocuments')
                  const nextRoute = this.$route.query.to
                  if (nextRoute) {
                    this.$router.push(nextRoute)
                  } else {
                    this.$router.push({
                      name: 'dashboard',
                    })
                  }
                } else {
                  this.loading = false
                  throw new Error(
                    // eslint-disable-next-line comma-dangle
                    'Invalid Token Received. Please try again later.',
                  )
                }
              } else {
                this.loading = false
                throw new Error('Unknown Error')
              }
            })
            .catch(err => {
              this.loading = false
              if (err.response) {
                if ('data' in err.response) {
                  if ('data' in err.response.data) {
                    if (Array.isArray(err.response.data.data)) {
                      err.response.data.data.forEach(param => {
                        this.showToast(
                          `Error ${err.response.status}`,
                          param.message,
                          'X',
                          'danger',
                          // eslint-disable-next-line comma-dangle
                          15000,
                        )
                      })
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.data.message}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                  }
                } else {
                  this.showToast(
                    `Error ${err.response.status}`,
                    `${err.response.statusText}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              } else {
                this.showToast(
                  'Error',
                  `${err.message}`,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                // eslint-disable-next-line function-paren-newline
              }
            })
        } else {
          this.loading = false
          this.showToast(
            'There seems to be a problem in your submission',
            undefined,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
